.messageContainer {
  margin-top: 30px;
}
.title {
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  color: #000;
}

.message {
  margin-top: 36px;
  line-height: normal;
  font-size: 15px;
  text-align: left;
  color: #000;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.message1 {
  font-size: 18px;
  text-align: center;
  line-height: normal;
  color: #242728;
}

.message2 {
  font-size: 38px;
  font-weight: var(--font-weight-medium);
  text-align: center;
  line-height: normal;
  color: #242728;
}

.message3 {
  font-size: 15px;
  text-align: left;
  line-height: normal;
  margin-top: 29px;
  color: #242728;
}

.message4 {
  font-size: 15px;  
  font-weight: var(--font-weight-bold);
  text-align: left;
  line-height: normal;
  /*margin-top: 29px;*/
}

.message5 {
  font-size: 15px;
  text-align: left;
  line-height: normal;
  /*margin-top: 29px;*/
}

.message6 {
  font-size: 15px;
  text-align: left;
  line-height: normal;
  margin-top: 29px;
}


/* iphone 5 */
@media screen and (device-aspect-ratio: 40/71) {
  .messageContainer {
    margin-top: 24px;
  }
  .message1 {
    font-size: 16px;
  }
  .message2 {
    font-size: 32px;
  }
  .message3 {
    font-size: 14px;
    text-align: left;
    line-height: normal;
    margin-top: 20px;
  }
}
