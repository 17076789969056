#toolbar-wrap {
  position: sticky;
  top: 0px;
  height: 60px;
  width: 100%;
}

#toolbar {
  position: absolute;
  left: 0px;
  right: 0px;
  padding: 15px;
  background-color: #f1f5f9;
}

#toolbar.down {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  padding-top: 25px;
}

.navBar {
  height: 60px;
  line-height: normal;
  font-size: 12px;
  text-align: center;
  color: var(--white);
  width: 100%;
}

.navIcon {
  width: 25px;
  height: 25px;
}

.navIconImgChat {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}

.containerFirstBtn {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
}

.horizontalTextLink {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.bottomTextLink1 {
  width: 100%;
}

.bottomTextLink2 {
  width: 100%;
}
.headerContainer {
  align-items: center;
  margin: 10px;
  justify-content: center;
  flex-direction: row;
  display: flex;
}
.boxHeaderContainer {
  top: 60px;
  z-index: 1;
  width: 100%;
  align-items: center;
  min-height: 54px;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  background-color: #fff;
  margin: auto;
  padding: 0 10px;
}

.boxHeaderContent {
  display: flex;
  align-items: center;
  max-width: 90%;
}

.boxContent {
  width: 90%;
}

.headerContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
}

.headerText {
  color: #a0a0a0;
  margin-left: 10px;
  margin-right: 10px;
  /*align-self: center;*/
}

.textHorizontalLine {
  margin: auto;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 9px;
  align-items: center;
  font-size: 13.5px;
  letter-spacing: 1px;
  color: #a0a0a0;
  margin-top: 5px;
  margin-bottom: 10px;
}
.textHorizontalLine::after,
.textHorizontalLine::before {
  content: '';
  height: 1px;
  display: block;
  background-color: currentColor;
}
.horizontalLine {
  width: 10%;
  height: 1px;
  background: #a0a0a0;
}
.wrapperWhiteMode {
  background-color: #f8f8f8;
}
.textLightDarkGray {
  color: #999999;
}

.ChatTextInputWrapper {
  display: flex;
  align-items: center;
  padding: 13px 24px 13px;
  background-color: #e5e5e5;
}

.PopupNewChatDesignWrapper {
  position: relative;
  line-height: 39px;
}

.displayFlex {
  display: flex;
}

.chatTextInputFlexEnd {
  align-items: flex-end;
}

.NewChatGroup {
  display: flex;
  align-items: center;
}

.sendImageIcon {
  margin-left: 28px;
}

@media only screen and (max-width: 320px) {
  .boxHeaderContent {
    max-width: 88%;
  }
  .stationName {
    max-width: 90px;
  }
}

@media only screen and (max-width: 390px) {
  .stationName {
    max-width: 100px;
  }
}

.sendImageWrapper {
  position: relative;
}

.testInput {
  position: absolute;
  right: 0;
  width: 30px;
  height: 23px;
  opacity: 0;
}

.chatInputGroup {
  width: 100%;
  bottom: 0;
  left: 0;
}
