.isSVGFillBlack {
  fill: black;
}
.isSVGStrokeBlack {
  stroke: black;
}
.isSVGBothBlack {
  fill: black;
  stroke: black;
}
.isSVGStrokeWhite {
  stroke: white !important;
}
.isSVGFillWhite {
  fill: white !important;
}
.isSVGStrokeGold {
  stroke:  var(--Main-Color) !important;
}
.isSVGFillGold {
  fill:  var(--Main-Color) !important;
}
.isSVGFillWhite {
  fill:  white !important;
}
.isSVGBothWhite {
  fill: white !important;
  stroke: white !important;
}
.marginRight {
  margin-right: 9px;
}
.customHairClass > path:not(:nth-child(3)) {
  stroke: white;
  fill: white;
}
.customHairClass > path:nth-child(3) {
  stroke: white;
}
.blurBackdrop {
  opacity: 0.2;
}

.disable {
  stroke: none;
}
