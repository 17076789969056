.chatTextInputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 14px;
  position: relative;
}

.chatTextInput {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px 2px 10px 16px;
  overflow: hidden;
  width: 100%;
  transition: all 0.2s linear;
}

.onTyping {
  width: calc(100% - 40px);
}

.textInputBox {
  background-color: #ffffff;
  resize: none;
  border: 0;
  padding: 0;
  width: 100%;
  padding-right: 10px;
}

.textInputBox::placeholder {
  font-size: 14px;
  letter-spacing: 1px;
  color: #a0a0a0;
  font-weight: 500;
  display: block !important;
}

.textInputNotTyping {
  height: 17px !important;
}

.displayBlock {
  display: block;
}

.sendButtonText {
  line-height: normal;
  font-size: 14px;
  text-align: right;
  letter-spacing: 1px;
  color: #a0a0a0;
  flex-grow: 0;
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}

.sendButtonVisible {
  opacity: 1;
  visibility: visible;
  transition: all 0.2s linear 0.1s;
}

.sendButtonImg {
  width: 36px;
  height: 36px;
  padding: 5px 0;
}

.loadingChatContainer {
  width: 50px;
  position: absolute;
  top: 0;
  right: 15px;
}

.textInputBoxWhiteMode {
  background-color: #f8f8f8;
}
.textInputBoxWhiteMode::placeholder {
  color: #999999;
}
.chatTextInputWhiteMode {
  border: 1px solid #c4c4c4;
  background-color: #f8f8f8;
}

.chatTextInputFlexEnd {
  align-items: flex-end;
}

.atTheBottom {
  transition: unset;
  transform: unset;
  bottom: 0%;
}
