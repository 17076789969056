.partnerTextMessage {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
}

.chatUserIcon {
  flex: 0 0 auto;
  width: 37.75px;
  height: 38px;
  left: 21.5px;
  background-color: black;
  border-radius: 50%;
  margin-right: 21px;
}

.chatPartnerTextBubble {
  height: min-content;
}
.textBlack {
  color: black;
  font-size: 13px;
}

.textBlack:hover {
  color: black;
}

.textRedNotice {
  color: #ba1c03;
  font-weight: bold;
}

.bubbleMessagesGroup {
  display: flex;
  align-items: flex-start;
  background: #fff;
  border-radius: 8px;
  padding: 10px 16px 10px 16px;
  border: 1px solid rgba(105, 105, 105, 0.5);
}

.textContent {
  font-size: 13px;
  font-weight: 500;
}
