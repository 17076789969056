.partnerTextMessage {
  display: inline-block;
  margin-bottom: 20px;
}

.chatUserIcon {
  flex: 0 0 auto;
  width: 37.75px;
  height: 38px;
  left: 21.5px;
  background-color: black;
  border-radius: 50%;
  margin-right: 16px;
}

.chatPartnerTextBubble {
  min-height: min-content;
  background: #ffffff;
  border-radius: 8px;
  padding: 14px 12px;
  border: 1px solid rgba(105, 105, 105, 0.5);
  max-width: 325px;
}

.messageNotVisible {
  font-weight: normal;
  line-height: normal;
  font-size: 13px;
  letter-spacing: 1px;
}

.parkName {
  color: #212529;
}
.textBlack {
  color: black;
}

.ChatTextHeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.chatPartnerTextHeader {
  display: flex;
  padding-bottom: 12px;
  border-bottom: 1px solid #b0b0b0;
}

.text1,
.text2 {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.text1 {
  color: #000;
}
.text2 {
  color: var(--Main-Color);
}
.text3 {
  font-weight: 400;
  font-size: 13px;
  color: #000;
  margin-left: 7px;
}

.text3Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}

.CafeWrapper {
  display: flex;
  justify-content: space-around;
}

.CafeItem {
  width: 90px;
  height: 90px;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
}

.CafeItemImg {
  width: 100%;
  height: 90px;
  object-fit: cover;
}

.CafeItemText {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3px;
  z-index: 1;
}

.CafeItemName {
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.CafeItemStation {
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.CafeItemBlur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.CafeIconSmall {
  width: 19px;
  height: 18px;
}

.textNote {
  color: #a0a0a0;
  font-size: 10px;
  font-weight: 400;
  padding-left: 6px;
}
