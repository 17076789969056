.container {
  display: flex;
  align-items: center;
}
.unread {
  line-height: normal;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  color: #ff0000;
}
