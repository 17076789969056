:root {
  --NotoSans: 'Noto Sans Japanese', sans-serif;
  --Roboto: 'Roboto', sans-serif;
  --NotoSerif: 'Noto Serif', serif;
  --Main-Color: #de9c4e;
  /*Color primitive*/
  --white: #ffffff;
  --black: #000000;
  --gold-50: #fcf6ee;
  --gold-100: #f8ebdc;
  --gold-200: #f2d7b8;
  --gold-300: #ebc495;
  --gold-400: #e5b071;
  --gold-500: #de9c4e;
  --gold-600: #a7753b;
  --gold-700: #6f4e27;
  --gold-800: #382714;
  --gold-900: #161008;
  --red-50: #fcefee;
  --red-100: #f8dddc;
  --red-200: #f2bcb8;
  --red-300: #eb9a95;
  --red-400: #e57971;
  --red-500: #de574e;
  --red-600: #a7413b;
  --red-700: #6f2c27;
  --red-800: #381614;
  --red-900: #160908;
  --blue-50: #f2f3ff;
  --blue-100: #e7edff;
  --blue-200: #c7cdff;
  --blue-300: #abb3fe;
  --blue-400: #8f9afe;
  --blue-500: #7381fe;
  --blue-600: #5661bf;
  --blue-700: #3a417f;
  --blue-800: #1d2040;
  --blue-900: #0c0d19;
  --pink-50: #fceff2;
  --pink-100: #f8dde4;
  --pink-200: #f1bcca;
  --pink-300: #ea9aaf;
  --pink-400: #e37995;
  --pink-500: #dc577a;
  --pink-600: #a5415c;
  --pink-700: #6e2c3d;
  --pink-800: #37161f;
  --pink-900: #16090c;
  --gray-50: #f5f5f5;
  --gray-100: #e0e0e0;
  --gray-200: #c2c2c2;
  --gray-300: #a3a3a3;
  --gray-400: #858585;
  --gray-500: #666666;
  --gray-600: #4d4d4d;
  --gray-700: #333333;
  --gray-800: #1a1a1a;
  --gray-900: #0a0a0a;
  --gray-silver: #c4c4c4;
  --gray-very-light: #f7f7f7;
  /*Color Semantic*/
  --primary-base: var(var(--gold-500), #de9c4e);
  --primary-lightest: var(var(--gold-50), #fcf6ee);
  --primary-lighter: var(var(--gold-100), #f8ebdc);
  --primary-light: var(var(--gold-200), #f2d7b8);
  --calendar-sat: var(var(--blue-500), #5661bf);
  --calendar-sun: var(var(--pink-500), #dc577a);
  --secondary-base: var(var(--red-500), #de574e);
  --bg-notification-new: var(var(--blue-50), #f2f3ff);
  --bg-base: var(var(--gray-50), #f5f5f5);
  --bg-application-bar: var(var(--gray-800), #1a1a1a);
  --border-base: var(var(--gray-100), #e0e0e0);
  --text-strong: var(var(--gray-800), #1a1a1a);
  --text-medium: var(var(--gray-300), #a3a3a3);
  --text-weak: var(var(--gray-200), #c2c2c2);
  /*Spacing*/
  --spacing-xx-small: 4px;
  --spacing-x-small: calc(var(--spacing-xx-small) * 2);
  --spacing-small: calc(var(--spacing-xx-small) * 3);
  --spacing-medium: calc(var(--spacing-xx-small) * 4);
  --spacing-large: calc(var(--spacing-xx-small) * 6);
  --spacing-x-large: calc(var(--spacing-xx-small) * 8);
  --spacing-xx-large: calc(var(--spacing-xx-small) * 10);
  --spacing-xxx-large: calc(var(--spacing-xx-small) * 14);
  /*Font*/
  --font-family-noto-sans-jp: 'Noto Sans Japanese', sans-serif;
  --font-family-inter: 'Inter';
  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-regular: 400;
  --font-size-xxs: 10px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 18px;
  --font-size-xl: 24px;
  --font-size-xxl: 32px;
  /*Typography*/
  --lineheight-xxs: 16px;
  --lineheight-xs: 16px;
  --lineheight-s: 24px;
  --lineheight-m: 24px;
  --lineheight-l: 24px;
  --lineheight-xl: 32px;
  --lineheight-xxl: 32px;
  /*Border*/
  --border-radius-s: 4pt;
  --border-radius-m: 8pt;
  --border-radius-l: 16pt;
  --border-radius-max: 10000pt;
}
/*Elevation*/
.elevation-floating {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.elevation-raised {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.02);
}
/*Typography*/
.typography-jp-xxs {
  font-size: var(--font-size-xxs);
  line-height: var(--lineheight-xxs);
  letter-spacing: 0.02em;
}
.typography-jp-xs {
  font-size: var(--font-size-xs);
  line-height: var(--lineheight-xs);
  letter-spacing: 0.017em;
}
.typography-jp-s {
  font-size: var(--font-size-s);
  line-height: var(--lineheight-s);
  letter-spacing: 0.014em;
}
.typography-jp-m {
  font-size: var(--font-size-m);
  line-height: var(--lineheight-m);
  letter-spacing: 0.011em;
}
.typography-jp-l {
  font-size: var(--font-size-l);
  line-height: var(--lineheight-l);
  letter-spacing: 0.008em;
}
.typography-jp-xl {
  font-size: var(--font-size-xl);
  line-height: var(--lineheight-xl);
  letter-spacing: 0.005em;
}
.typography-jp-xxl {
  font-size: var(--font-size-xxl);
  line-height: var(--lineheight-xxl);
  letter-spacing: -0.001em;
}

.typography-en-xxs {
  font-size: var(--font-size-xxs);
  line-height: var(--lineheight-xxs);
  letter-spacing: 0.015em;
}
.typography-en-xs {
  font-size: var(--font-size-xs);
  line-height: var(--lineheight-xs);
  letter-spacing: 0.01em;
}
.typography-en-s {
  font-size: var(--font-size-s);
  line-height: var(--lineheight-s);
  letter-spacing: 0.005em;
}
.typography-en-m {
  font-size: var(--font-size-m);
  line-height: var(--lineheight-m);
}
.typography-en-l {
  font-size: var(--font-size-l);
  line-height: var(--lineheight-l);
  letter-spacing: -0.005em;
}
.typography-en-xl {
  font-size: var(--font-size-xl);
  line-height: var(--lineheight-xl);
  letter-spacing: -0.01em;
}
.typography-en-xxl {
  font-size: var(--font-size-xxl);
  line-height: var(--lineheight-xxl);
  letter-spacing: -0.02em;
}
html,
body,
#app,
#app > div {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--NotoSans) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  -webkit-overflow-scrolling: unset;
  overflow: hidden;
}

body.isIOSSafari {
  /* fix full screen problem in ios safari */
  /* height: calc(100vh + 1px); disabled for chat feature plz revert  if problem*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

#root.isIOSSafari {
  /* fix full screen problem in ios safari */
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%; disabled for chat feature plz revert  if problem*/
}

:global(.am-tab-bar-bar::before) {
  background-color: #1a1a1a !important;
}

.weui-picker__item {
  font-size: 18px !important;
}

.am-tab-bar-bar {
  height: 65px !important;
  align-items: flex-start !important;
  padding-top: 10px;
}

.am-badge-dot {
  background-color: #9d150c !important;
  left: 19px !important;
}

.line-fill-2-white > g > path {
  fill: var(--white);
}

.line-fill-2-black > g > path {
  fill: var(--black);
}

.line-fill-2-main-color > g > path {
  fill: var(--Main-Color);
}

.line-fill-1-white > path {
  fill: var(--white);
}

.line-fill-1-black > path {
  fill: var(--black);
}

.line-fill-1-main-color > path {
  fill: var(--Main-Color);
}

.black-hair-man-white > g > path:first-child {
  fill: var(--white);
}

.black-hair-man-black > g > path:first-child {
  fill: var(--black);
}

.black-hair-man-main-color > g > path:first-child {
  fill: var(--Main-Color);
}

.black-hair-man-white > g > path:last-child {
  stroke: var(--white);
}

.black-hair-man-black > g > path:last-child {
  stroke: var(--black);
}

.black-hair-man-main-color > g > path:last-child {
  stroke: var(--Main-Color);
}

.globalErrorMessages {
  color: #242728;
  font-family: var(--NotoSans);
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: normal;
}

.globalErrorTitle {
  color: var(--black);
  text-align: center;
  font-family: var(--NotoSans);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: normal;
}
