.chatRoom {
  padding: 20px 20px 0 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.scrollTo {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
}

.loadingMessageContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}