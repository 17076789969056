.partnerTextMessage {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 15px;
}

.chatUserIcon {
  flex: 0 0 auto;
  width: 37.75px;
  height: 38px;
  left: 21.5px;
  background-color: black;
  border-radius: 50%;
  margin-right: 10px;
}

.chatPartnerTextBubble {
  min-height: min-content;
  width: 260px;
  background: #ffffff;
  border-radius: 26px;
  padding: 11px 16px 11px 16px;
}

.messageNotVisible {
  font-weight: normal;
  line-height: normal;
  font-size: 13px;
  letter-spacing: 1px;
}
