.navIcon {
  width: 25px;
  height: 25px;
}
.containerFull {
  width: 100%;
  height: 100%;
  background-color: #111111;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.confirmationPopupTitle {
  line-height: normal;
  font-size: 18px;
  text-align: center;
  color: #000000;
  font-weight: 600;
}
.suddenlyDroppedContainer {
  text-align: right;
  margin-top: 16px;
}
.suddenlyDropped {
  color: var(--Main-Color);
  text-decoration: underline;
}
.messageClassName {
  font-size: 15px;
  color: #242728;
  display: flex;
  justify-content: center;
}
.displayUnset {
  display: unset !important;
}
.messageSpace {
  margin-bottom: 20px;
}
.messageSpace:last-child {
  margin-bottom: 0;
}
.confirmationPopupFooter {
  margin-top: 43px;
}

.messageClassNameCancelSampleDate {
  font-size: 15px;
  color: #242728;
  margin-top: 20px;
}

.confirmationPopupMessagesContainer {
  margin-top: 32px;
}
.contentContainer {
  background-color: #111111;
  flex: 1;
  overflow: auto;
}

.premiumCrown {
  vertical-align: top;
  padding-right: 4px;
  width: 36px;
  height: 22px;
  margin-bottom: 2px;
}
.labelSubNonBorder {
  display: flex;
  align-items: center;
  color: var(--black);
  padding: 15px 22px;
}
.image {
  min-width: 45px;
  margin-right: 20px;
}
.specialText {
  color: var(--Main-Color);
}
.whiteModeText {
  color: #000000;
}
.flexStart {
  align-items: flex-start;
}
@media screen and (device-aspect-ratio: 40/71) {
  /* .content-container {
    padding-top: 60px;
  } */
}
.containerAvatar {
  margin-top: 27px;
  position: relative;
  text-align: center;
  color: white;
  margin-bottom: 25px;
}
.imageAvatarPartner {
  width: 80px;
  height: 80px;
  border-radius: 160px;
  margin-bottom: 18px;
}

.blur {
  -webkit-filter: blur(2px); /* Safari 6.0 - 9.0 */
  filter: blur(2px);
}

.centeredName {
  position: absolute;
  top: 110%;
  left: 50%;
  color: white;
  font-size: 18px;
  line-height: 21px;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  display: flex;
  align-items: center;
  text-decoration: underline;
}
.centeredNameWhite {
  color: black;
}
.centeredNameBlackPlan {
  top: 80%;
  color: white;
  text-decoration: underline;
}
.trancert-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  resize: horizontal;
}
.listDetail {
  flex-direction: row;
  background-color: #111111;
}
.listDetailWhite {
  flex-direction: row;
  background-color: #f8f8f8;
}
.labelTitleWhite {
  color: black;
}
.labelTitle {
  display: flex;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 22px;
  padding-right: 22px;
  border-bottom: 0.1px #575757 solid;
  align-items: center;
  justify-content: space-between;
}
.labelContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 18px;
}
.labelNonBorder {
  border-bottom: 0px;
}
.paddingVertical {
  background-color: #eaeaea;
  margin-left: 11px;
  margin-right: 11px;
}
.containerCoffe {
  position: relative;
  text-align: center;
  display: flex;
}
.imageCoffee {
  flex: 0 0 auto;
  height: 267px;
  width: 100%;
  object-fit: cover;
}
.backgroundCoffee {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent 60%, black 100%);
}
.txtCoffee {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 20px;
  line-height: 23px;
  text-align: left;
  font-weight: 200;
  color: white;
  margin-bottom: 25px;
  /* transform: translate(-50%, -50%); */
}

.premium-crown {
  vertical-align: bottom;
  padding-right: 4px;
  width: 36px;
  height: 22px;
}

.crownArea {
  color: white;
  font-size: 18px;
  line-height: 21px;
  vertical-align: top;
}

.txtTitleCrown {
  width: 100%;
  color: white;
  font-size: 18px;
  line-height: 21px;
  vertical-align: bottom;
  margin-left: 2px;
}
.titleClassName {
  font-weight: 600;
  color: #000;
  line-height: 21px;
  font-size: 18px;
  text-align: center;
}
.txtTitleUserRating {
  width: 100%;
  color: var(--Main-Color);
  font-size: 28px;
  line-height: 21px;
  margin-left: 2px;
  font-weight: 500;
}

.txtTitle {
  width: 100%;
  color: white;
  font-size: 18px;
  line-height: 21px;
}
.txtTitleWhite {
  color: black;
}
.txtTitleRight {
  margin-top: 18px;
  font-size: 15px;
  line-height: 20px;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

.labelSub {
  display: flex;
  width: 100%;
  padding: 23px 22px;
  border-bottom: 0.1px #575757 solid;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.relativePosition {
  position: relative !important;
}

.labelSubBtn {
  display: flex;
  width: 100%;
  padding-top: 43px;
  padding-bottom: 43px;
  padding-left: 22px;
  padding-right: 22px;
  align-items: center;
  justify-content: space-between;
}

.txtSub {
  color: #a0a0a0;
  font-size: 16px;
  line-height: 19px;
}
.txtSubWhite {
  color: black;
}
.txtSubRightContainer {
  max-width: calc(100% - 150px);
  display: inline-block;
  line-height: initial;
}
.txtSubRightContainerWithTag {
  flex: 0 0 calc(100% - 50px);
  max-width: calc(100% - 50px);
}
.txtSubRightContainerCrown {
  position: absolute;
  right: 22px;
  bottom: 5px;
}
.txtSubRightCrown {
  font-size: 13px;
  text-decoration: underline;
  display: inline-block;
  float: right;
  margin-top: 5px;
}
.txtSubRight {
  max-width: 100%;
  word-wrap: break-word;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  text-align: right;
}

.contentPlace {
  padding-top: 13px;
  padding-bottom: 8px;
  padding-left: 11px;
  width: 100%;
  display: flex;
}
.contentPlaceFisrt {
  padding-top: 30px;
  padding-bottom: 8px;
  padding-left: 11px;
  width: 100%;
  display: flex;
}
.contentPlaceLast {
  padding-top: 13px;
  padding-bottom: 15px;
  padding-left: 11px;
  width: 100%;
  display: flex;
}
.labelPlace {
  color: #575757;
  font-size: 13px;
  line-height: 15px;
  flex: 1;
}
.txtContentPlace {
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  flex: 2;
  padding-right: 11px;
}
.starClass {
  color: white;
  background-color: var(--Main-Color);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 3px;
  margin-left: 2px;
}

.navIcon {
  width: 25px;
  height: 25px;
}
.btnDialogBtm {
  width: 100%;
  height: 60px;
  background: var(--Main-Color);
  border-radius: 45px;
  border: 0px solid var(--Main-Color);
  line-height: normal;
  color: #fff;
  /*color: #000000;*/
  font-size: 16.6px;
  letter-spacing: 1px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4.23529px 4.23529px;
}
.messageClassName {
  font-size: 15px;
  color: #242728;
}
.greyButton {
  width: 86%;
  height: 35px;
  margin-right: 7px;
  background: #222222;
  color: #7c7c7c;
  border-radius: 8px;
  display: flex;
  font-size: 15px;
  margin-top: 10%;
  margin-bottom: 10%;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  justify-content: center;
}
.goldenButton {
  width: 86%;
  height: 60px;
  background: #000000;
  border-radius: 45px;
  border: 1px solid var(--Main-Color);
  color: var(--Main-Color);
  display: flex;
  font-size: 16px;
  margin-top: 10%;
  margin-bottom: 12%;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  justify-content: center;
}
.buttons {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  /*margin-bottom: 52px;*/
  justify-content: center;
  align-items: center;
}

.buttonsWithChat {
  margin-bottom: 70px;
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
}

.button {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--Main-Color);
  line-height: 19px;
  font-size: 12px;
  font-weight: 500;
}

.buttonOk {
  color: #000;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
}

.buttonPositive {
  margin-bottom: 16px;
}

.buttonNegative {
  background-color: #a0a0a0;
}

.note {
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  color: #047bfd;
  text-decoration: underline;
}

.messageContainer {
  margin-top: 10px;
}

.messageContainerPopupDatingTime {
  margin-top: 20px;
}

.message {
  line-height: normal;
  font-size: 14px;
  text-align: left;
  padding-top: 20px;
}

.messagePopupDatingTime {
  line-height: normal;
  font-size: 15px;
  text-align: left;
  padding-top: 5px;
}

.messageLast {
  line-height: normal;
  font-size: 15px;
  text-align: left;
}

ul {
  padding-left: 15px;
}

li {
  padding-top: 20px;
}

li:first-child {
  padding-top: 0px;
}

.contact {
  font-size: 15px;
  font-weight: 400;
  color: #047bfd;
  text-decoration: underline;
}
.containerBlurCredit {
  display: flex;
  justify-content: center;
  height: 600px;
}
.labelSubBtnAbsolute {
  position: absolute;
  bottom: 20px;
  z-index: 100;
  display: flex;
  width: 100%;
  padding: 28px;
  align-items: center;
  justify-content: space-between;
}
.blurDiv {
  background-image: url('../../assets/common/blur1.png');

  /* Add the blur effect */
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */

  /* Full height */
  height: 600px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 85px;
  position: absolute;
  z-index: 50;

  /* Center and scale the image nicely */
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: contain;

  justify-content: center;
  align-items: center;
}
.containerBlurCredit1 {
  display: flex;
  justify-content: center;
  height: 200px;
}
.blurDiv1 {
  background-image: url('../../assets/common/blur2.png');
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  height: 200px;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: contain;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 50;
}
.noCardBlur {
  filter: blur(6px) brightness(0.75);
  -webkit-filter: blur(6px) brightness(0.75);
  -moz-filter: blur(6px) brightness(0.75);
  -ms-filter: blur(6px) brightness(0.75);
  -o-filter: blur(6px) brightness(0.75);
}

.headerPopUp {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.titlePopUp {
  line-height: normal;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.closeBtnContainer {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  opacity: 1;
  background-color: #e5e5e5;
  z-index: 1010;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeBtn {
  position: absolute;
  width: 17px;
  height: 17px;
  border-radius: 8.5px;
  opacity: 1;
  background-color: var(--Main-Color);
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}
.triangleDown {
  position: absolute;
  bottom: -9px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #ffffff;
  z-index: 999;
}
.triangleDownBorder {
  position: absolute;
  bottom: -11px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 11px solid var(--Main-Color);
}
.containerTooltips {
  display: flex;
  background-color: #ffffff;
  width: 95%;
  height: 38px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: -25px 8px;
  padding: 10px;
  position: relative;
  border: 1px solid var(--Main-Color);
}
.txtTooltips {
  font-size: 13px;
  color: var(--Main-Color);
  line-height: 15px;
}

.closeButtonXContainer {
  position: absolute;
  right: -9px;
  top: -9px;
  width: 18px;
  height: 18px;
  opacity: 1;
  background-color: #e5e5e5;
  z-index: 1010;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeButtonX {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  opacity: 1;
  background-color: var(--Main-Color);
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeButtonX:hover {
  opacity: 1;
}
.closeButtonX:before,
.closeButtonX:after {
  position: absolute;
  content: ' ';
  height: 10px;
  width: 1px;
  margin-left: 0.5px;
  background-color: white;
}
.closeButtonX:before {
  transform: rotate(45deg);
}
.closeButtonX:after {
  transform: rotate(-45deg);
}

.iconQuestion {
  height: 22px;
  width: 20px;
  margin-left: 4px;
}

.iconQuestionYellow {
  height: 20px;
  width: 18px;
  margin-left: 4px;
  vertical-align: bottom;
}

.tabButton {
  font-size: 15px;
  width: 100%;
  height: 41px;
  color: #ffffff;
  background: linear-gradient(182.87deg, #646464 -12.73%, #242424 80.72%);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
}
.tabButtonWhite {
  background: #c4c4c4;
}
.tabSelected {
  background: var(--Main-Color);
}
.leftTab {
  margin-left: 10px;
  margin-right: 2.5px;
}
.rightTab {
  margin-left: 2.5px;
  margin-right: 10px;
}
.tabSection {
  display: flex;
  margin-top: 3rem;
}
.blackPlanTabSection {
  margin-top: 1rem;
}
.warningText {
  color: #ff0000;
  font-size: 11px;
}
.buttonDate {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--Main-Color);
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  letter-spacing: 1px;
  font-weight: 400;
}
.buttonNegative {
  background-color: #a0a0a0;
}

.labelCancel {
  padding-left: 11px;
  padding-right: 11px;
}

.cancelButton {
  width: 86%;
  height: 35px;
  background: #222222;
  color: #7c7c7c;
  border-radius: 8px;
  display: flex;
  font-size: 15px;
  margin-top: 10%;
  margin-bottom: 15%;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  justify-content: center;
}
.cancelButton.isSampleDate {
  margin: 36px 0px 136px 0px;
  width: auto;
  padding: 0;
  background: transparent;
  color: var(--gray-300);
  text-decoration-line: underline;
  text-underline-position: under;
  text-underline-offset: 1px;
  font-size: var(--font-size-m);
  line-height: var(--lineheight-s);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  font-family: var(--NotoSans);
}
.txtTitleCafeInfo {
  margin-left: 11px;
}
.schoolContent {
  max-width: 80%;
}

.notifyPopup {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.checkboxContainer {
  display: flex;
  flex-flow: row wrap;
  margin: 30px 5px 0 5px;
}
.checkboxContainerForFemale {
  display: flex;
  flex-direction: column;
  margin: 30px 5px 0 5px;
  max-height: 160px;
  overflow-y: scroll;
}
.containerFullWhite {
  background-color: white;
}
.navBarWhite {
  border-bottom: 2px solid white;
  background-color: white;
}
.activeWhiteMode02 {
  background-color: #ffffff;
}
.containerFullWhite {
  background-color: #f8f8f8;
}
.textWhite {
  color: white;
}
.question {
  display: flex;
  align-items: flex-start;
}
.noBorder {
  border: none;
}
.dateItem {
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.icon {
  width: 16px;
  height: 16px;
}

@media screen and (min-width: 320px) and (max-width: 361px) {
  .notifyPopup {
    font-size: 12px;
  }
  .labelTitle {
    padding-top: 10px;
  }
}

.noFloating {
  position: relative;
}
.noFloating .labelSubBtnAbsolute {
  bottom: auto;
  margin-top: 100px;
  padding-top: 0;
}
.noFloating .labelSubBtnAbsolute2 {
  position: fixed;
  bottom: 20px;
  background: transparent;
}
.detailInfoLinkBtn {
  color: var(--Main-Color);
  width: fit-content;
  margin-top: unset;
  line-height: unset;
  float: right;
  font-weight: bold;
}
.detailInfoLinkGroup {
  width: 100%;
  float: right;
  margin-top: var(--spacing-x-small);
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
  .txtSubRightCrown {
    font-size: 13px;
  }
  .txtTitleCrown {
    font-size: 16px;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .txtSubRightCrown {
    font-size: 13px;
  }
  .txtTitleCrown {
    font-size: 16px;
  }
}

/* samsung galaxy s8+ */
@media only screen and (min-device-width: 360px) and (max-device-height: 740px) {
  .txtSubRightCrown {
    font-size: 13px;
  }
  .txtTitleCrown {
    font-size: 15px;
  }
}
.notice {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 133.5%;
  margin-left: 5px;
  letter-spacing: 0.035em;
  color: #ff1000;
  flex: 1;
}
.buttonError {
  width: 100%;
  text-align: center;
  margin: 0 0 7px;
}
.disableBtn {
  background: #c4c4c4 !important;
  color: #ffffff;
}

.iosBlur {
  background: linear-gradient(
    180deg,
    rgba(229, 229, 229, 0.5) 0%,
    rgba(229, 229, 229, 0.729167) 48.52%,
    #e5e5e5 100%
  );
  width: calc(100% - 10px);
  height: 25px;
  transform: translateY(-100%);
}

.iosBlurWhiteMode {
  background: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0.4) 0%,
    rgba(248, 248, 248, 0.66897) 42.96%,
    #f8f8f8 95.83%
  );
}

.certificationTag {
  width: 55px;
  height: 22.5px;
  background: #000000;
  border: 1.18367px solid #cfcfcf;
  border-radius: 3.55102px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.certificationTagText {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1.8px;
  color: #ffffff;
  text-align: center;
}

.popupContentStyle {
  height: auto;
}

.mb140 {
  margin-bottom: 140px;
}

.middleText {
  vertical-align: middle;
}

.bachelorRateStyle {
  margin-right: 10px;
}

.zeroLineHeight {
  line-height: 0;
}

.crownWithTextArea {
  display: block;
  margin-bottom: 15px;
}
.blackUserBackground {
  background-image: url('../../assets/PartnerDetail/black_partner_detail_background.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 101% 100%;
  margin: 0;
  padding-top: 28px;
  padding-bottom: 40px;
}

.blackPlanHelpPopupMessages {
  text-align: left;
}

.textLink {
  align-self: stretch;
  text-align: right;
  font-family: var(--NotoSans);
  font-size: var(--font-size-m);
  font-style: 'normal';
  font-weight: var(--font-weight-regular);
  line-height: var(--font-size-xl);
  letter-spacing: 0.176px;
  text-decoration: underline;
  color: var(--Main-Color);
  margin-top: var(--spacing-x-small);
}

.unusedDatingItemPopupMessages {
  color: black;
  font-size: var( --font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--lineheight-s);
  letter-spacing: 0.18px;
  margin-top: 24px;
  margin-bottom: -16px;
}

.unusedDatingItemPopupMessages .centerContent {
  text-align: center;
}

.returnDatingItemList{
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 18px;
}

.returnDatingItemConatiner {
  display: flex;
  gap: 12px;
  align-items: center;
}

.returnDatingItemConatiner .iconContainer {
  padding: 5px;
  border-radius: 100px;
  box-shadow: 0px 0px 4.588235378265381px rgba(0, 0, 0, 0.1);
  align-self: center;
}

.returnDatingItemConatiner .iconContainer .datingIcon {
  width: 24px;
  height: 24px;
}

.returnDatingItemConatiner .content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 0.5px var(--gray-200) solid;
  height: fit-content;
}

.returnDatingItemConatiner .content .name {
  font-size: var( --font-size-m);
  font-weight: var(--font-weight-bold);
  line-height: 21.05px;
  letter-spacing: 0.13px;
  margin: 0;
}
