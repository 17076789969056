.titleContainer {
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
}

.button {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--Main-Color);
  line-height: 19px;
  font-size: 12px;
  font-weight: 500;
}

.buttonOk {
  color: #000;
  line-height: normal;
  font-size: 16px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 400;
}

.buttonPositive {
  margin-bottom: 16px;
}

.buttonNegative {
  background-color: #a0a0a0;
}

.note {
  margin-top: 10px;
  font-size: 11.6px;
  text-align: center;
  font-weight: 400;
}

.messageContainer {
  margin-top: 25px;
}

.message {
  line-height: normal;
  font-size: 16px;
  text-align: left;
}

@media only screen and (min-width: 375px) and (min-height: 812px) {
  .title {
    font-size: 16px;
  }
}
@media only screen and (min-width: 375px) and (min-height: 667px) {
  .title {
    font-size: 16px;
  }
}
