.userTextMessage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;
  white-space: pre-wrap;
}

.userTextShowDate {
  padding-top: 35px;
}

.chatUserTextBubble {
  min-height: min-content;
  max-width: 263px;
  background: #dddddd;
  color: #000000;
  font-weight: 400;
  border-radius: 8px;
  padding: 10px 12px;
  word-wrap: break-word;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 1px;
  margin-left: 5px;
}

.chatUserCreateTime {
  line-height: normal;
  font-size: 11px;
  font-weight: 400;
  text-align: right;
  letter-spacing: 1px;
  color: #a0a0a0;
}

.chatUserCreateDate {
  line-height: normal;
  position: absolute;
  font-size: 12px;
  color: #a0a0a0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  top: 10px;
}
.activeWhiteMode {
  background: var(--Main-Color);
}
.textWhiteMode {
  color: var(--Main-Color);
}

.chatUserImageWrapper {
  width: 160px;
  margin-left: 5px;
  border-radius: 7px;
  overflow: hidden;
}

.chatUserImage {
  width: 100%;
}

.imagePreviewFull {
  max-height: 70vh;
  max-width: 90%;
}
.closeImage {
  width: 1rem;
  height: 1rem;
  position: fixed;
  top: 68px;
  left: 10px;
  z-index: 2;
}

.chatUserImagePreviewWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
}

.chatUserImagePreview {
  width: 100%;
  padding-top: 1rem;
  text-align: center;
}

@media only screen and (max-width: 370px) {
  .chatUserTextBubble {
    max-width: 220px;
  }
}
