.partnerTextMessage {
  display: inline-block;
  margin-bottom: 20px;
}

.chatUserIcon {
  flex: 0 0 auto;
  width: 37.75px;
  height: 38px;
  left: 21.5px;
  background-color: black;
  border-radius: 50%;
  margin-right: 20px;
}

.chatPartnerTextBubble {
  min-height: min-content;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 9px 12px;
  display: flex;
  border: 1px solid rgba(105, 105, 105, 0.5);
  max-width: 325px;
}

.messageNotVisible {
  font-weight: normal;
  line-height: normal;
  font-size: 13px;
  letter-spacing: 1px;
}

.parkName {
  color: #212529;
}

.chatLinkText {
  width: 85%;
  height: 18px;
  line-height: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  white-space: nowrap;
  color: var(--Main-Color);
}
.textBlack {
  color: black;
}

.text {
  color: #000;
  font-size: 13px;
  font-weight: 500;
}

.textBottom {
  margin-top: 1rem;
}

.textNote {
  color: #a0a0a0;
  font-size: 10px;
  font-weight: 400;
  padding-left: 6px;
}
