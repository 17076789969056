.NewChatActionGroup {
  padding: 30px 20px;
  background-color: #ffffff;
}

.UpperGroup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px dashed #c4c4c4;
  padding-bottom: 18px;
}

.LowerGroup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 13px;
}

.Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 7px;
}

.ItemText {
  font-size: 11px;
  margin: 0;
}

.ItemSeparate {
  height: 44px;
  border-left: 1px dashed #c4c4c4;
}
