.topBannerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e7e7e7;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

.bannerCloseButton {
  margin: 12px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

.appIcon {
  margin-right: 16px;
  width: 45px;
  height: 45px;
}

.topBannerNormalContent {
  font-size: 10px;
  font-weight: 500;
}

.topBannerHighlightContent {
  font-size: 12px;
  font-weight: 700;
}

.downloadButton {
  width: 22%;
  height: 43%;
  background-color: var(--Main-Color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 1.5%;
}

.downloadButtonText {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
}
