.PopupNewChatDesign {
  width: 313px;
  position: absolute;
  padding: 20px;
  background-color: white;
  bottom: calc(100% + 16px);
  left: 0;
  border-radius: 6px;
  z-index: 2;
}

.PopupNewChatDesignArrow {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: calc(100% + 8px);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid white;
  z-index: 2;
}

.GroupAction {
  padding: 19px 14px;
  border-radius: 6px;
  border: 1px dashed #a0a0a0;
  margin: 0px 9px;
}

.UpperGroup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px dashed #c4c4c4;
  padding-bottom: 13px;
}

.Item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 7px;
}

.ItemText {
  font-size: 7px;
  margin: 0;
}

.LowerGroup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 13px;
}

.ItemSeparate {
  height: 44px;
  border-left: 1px dashed #c4c4c4;
}

.GroupText {
  text-align: center;
  margin-top: 22px;
}

.GroupTextTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.GroupTextTitle > h3 {
  font-size: 13px;
  font-weight: bold;
  margin: 0;
}

.GroupTextTitleIcon {
  width: 14px;
  height: 14px;
}

.GroupTextDescription {
  color: #6e6e6e;
  font-size: 10px;
  margin: 0;
  margin: 8px 0;
}

.GroupTextButton {
  width: 87px;
  color: #fff;
  background-color: #000000;
  font-size: 13px;
  font-weight: bold;
  border-radius: 7px;
  border: none;
  padding: 9px 0;
}

.PopupNewChatDesignOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 1;
}
