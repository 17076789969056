.read {
  line-height: normal;
  font-size: 11px;
  text-align: right;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
  color: #a0a0a0;
}
