.partnerTextMessage {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
  align-items: flex-end;
  position: relative;
  white-space: pre-wrap;
}

.partnerTextMessageGroup {
  display: flex;
}

.chatUserIcon {
  flex: 0 0 auto;
  width: 37.75px;
  height: 38px;
  left: 21.5px;
  background-color: black;
  border-radius: 50%;
  margin-right: 5px;
}

.partnerTextShowDate {
  padding-top: 35px;
}

.chatPartnerTextBubbleGroup {
  position: relative;
  margin-left: 13px;
}

.chatPartnerTextBubble {
  height: min-content;
  max-width: 260px;
  background: #ffffff;
  color: #000;
  border-radius: 8px;
  padding: 11px 16px;
  word-wrap: break-word;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 1px;
  overflow-x: hidden;
  margin-right: 5px;
}

.chatPartnerTextBubbleArrow {
  position: absolute;
  top: 4px;
  left: -13px;
  border-top: 10px solid #fff;
  border-left: 15px solid transparent;
}

.chatPartnerCreateTime {
  line-height: normal;
  font-size: 10px;
  text-align: right;
  letter-spacing: 1px;
  color: #a0a0a0;
}

.chatPartnerCreateDate {
  line-height: normal;
  position: absolute;
  font-size: 12px;
  color: #a0a0a0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  text-align: center;
  top: 10px;
}

.blur {
  filter: blur(3px);
}
.textBlack {
  color: black;
}

.chatPartnerImageWrapper {
  width: 160px;
  margin-right: 5px;
  border-radius: 7px;
  overflow: hidden;
}

.chatPartnerImage {
  width: 100%;
}

.imagePreviewFull {
  max-height: 70vh;
  max-width: 90%;
}

.closeImage {
  width: 1rem;
  height: 1rem;
  position: fixed;
  top: 68px;
  left: 10px;
  z-index: 2;
}

.chatPartnerImagePreviewWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
}

.chatPartnerImagePreview {
  width: 100%;
  padding-top: 1rem;
  text-align: center;
}

@media only screen and (max-width: 370px) {
  .chatPartnerTextBubble {
    max-width: 220px;
  }
}
