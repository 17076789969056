.containerFull {
  flex: 1 1 auto;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.underlineText {
  text-decoration: underline;
  text-underline-position: under;
}
.weekTitleContainer {
  flex: 0 0 auto;
  width: 100%;
}
.footerContainer {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  width: 100%;
  position: relative;
}
.marginBottomZero {
  margin-bottom: 0;
}

.textNoOpenDate {
  color: #fff;
  width: 100%;
  font-size: 21px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.contentContainer {
  /* display: flex;
  flex-direction: column; */
  display: block;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;
  position: relative;
}

.titleClassName {
  font-weight: var(--font-weight-medium);
  color: #000;
  font-size: 18px;
  text-align: center;
  line-height: normal;
  font-style: normal;
  font-family: var(--NotoSans);
}

.messageClassName {
  font-size: 15px;
  color: #242728;
  font-family: var(--NotoSans);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: normal;
  display: flex;
}

.bachelorMemberWelcomeMessageClassName {
  color: var(--black);
  font-size: 15px;
  font-weight: var(--font-weight-regular);
  line-height: 22px;
  letter-spacing: 0.168px;
}

.validateErrorMessage {
  font-size: 18px;
  color: #242728;
  display: flex;
  justify-content: center;
  font-weight: bold;
  white-space: pre-line;
  text-align: center;
}

.centerTextInsideFlexBox {
  justify-content: center;
}

.messageBold {
  font-weight: bold;
}

.textUnderLine {
  text-decoration: underline;
}

.positiveButtonClassName,
.stylePositiveButton {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: var(--Main-Color);
  font-weight: 300;
  margin-bottom: 16px;
  line-height: normal;
  font-size: 16.6px;
  text-align: center;
  letter-spacing: 1px;
  color: #000000;
}

.stylePositiveButton {
  border-radius: var(--border-radius-s);
  height: 54px;
  margin: 0 var(--spacing-x-small);
  font-size: 15px;
  font-weight: var(--font-weight-medium);
  line-height: 22.93px;
  letter-spacing: 1.051px;
}

.bodyStyle {
  padding-bottom: 30px;
}

.footerClassName {
  margin-top: 30px;
}

.customContentStyle {
  padding-bottom: 19px;
  margin-bottom: 0;
}

.messagesContainerAddClass {
  margin-top: 20px;
}

.popupContent {
  margin: 0 auto;
}

.negativeButtonClassName {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  background-color: #a0a0a0;
  line-height: normal;
  font-size: 16.6px;
  text-align: center;
  letter-spacing: 1px;
  color: #000000;
}

.dateButtonRight {
  /*padding-right: 10%;*/
  width: 50%;
  text-align: center;
  /*text-align: right;*/
  line-height: 20px;
  color: white;
  align-items: center;
  justify-content: center;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 200;
}
.dateButtonLeft {
  /*padding-left: 10%;*/
  width: 50%;
  text-align: center;
  /*background-color: #007bff;*/
  line-height: 26px;
  color: white;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 200;
  white-space: nowrap;
}
.dateButtonCenter {
  /*padding-left: 10%;*/
  width: 100%;
  text-align: center;
  /*background-color: #007bff;*/
  line-height: 26px;
  /* color: #5d5d5d; */
  color: white;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 200;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}
.dateButtonCenter > div {
  margin-left: 10px;
}
.dateButtonCenter > div:last-child {
  margin-left: 10px;
}
.dateButtonCenterOpen {
  color: white;
}

.dateExpireToday {
  background: var(--Main-Color);
  position: absolute;
  width: 100px;
  height: 63px;
  border-radius: 10px;
  color: white;
  clip-path: polygon(0 0, 0 55px, 55px 0);
  left: 0;
  top: 0;
}

.dateExpireToday p {
  font-size: 11px;
  font-weight: 500;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: -10px 0 0 -10px;
}
.dateAwaitingCancel {
  background: #de574e;
}

.dateAwaitingCancel p {
  margin: -20px 0 0 -10px;
  font-size: 13px;
  font-weight: 300;
}

.dayGWHoliday {
  background: #9d150c;
}

.recommendationText {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 0.025em;
  color: var(--Main-Color);
  margin: 0.5rem 0;
}
.dateText1 {
  color: var(--Main-Color);
  line-height: normal;
  text-align: center;
  font-size: 17px;
  font-weight: 300;
}

.dateText2 {
  color: white;
  padding: 3px 1em 0;
  line-height: normal;
  font-size: 19px;
  font-weight: 300;
  text-align: center;
}

.dateBookedText1 {
  color: white;
  line-height: normal;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

.dateBookedText2 {
  color: white;
  padding: 3px 1em 0;
  line-height: normal;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.dateButton {
  width: 100%;
  height: 63px;
  background: #282828;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0 35px;
}
.dateButtonContainer.dateButtonFirst {
  margin-top: 55px;
}

.dateButton2 {
  margin-top: 20px;
}

.textButton {
  text-align: center;
  vertical-align: middle;
  color: #000000;
  line-height: 23px;
  letter-spacing: 1px;
  font-size: 16px;
}

.confirmButton {
  display: flex;
  height: 56px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #575757;
}

.confirmButtonActive {
  background-color: var(--Main-Color);
}

.helpButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 65px;
}

.helpButtonText {
  color: #a0a0a0;
  line-height: 18px;
  font-size: 15px;
  text-decoration: underline;
  position: relative;
  left: 6px;
  cursor: pointer;
}

.helpButtonText:hover {
  color: #a0a0a0;
}

.suggestionText {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 59px;
  padding: 32px 10px 16px;
  background: linear-gradient(
    360deg,
    #f8f8f8 48.44%,
    rgba(248, 248, 248, 0) 100%
  );
}

.helpButtonIcon {
  display: flex;
  width: 23px;
  height: 23px;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  position: relative;
  left: 6px;
}

.helpButtonImage {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.weekSectionOneScreen {
  color: #a0a0a0;
  margin-top: 1rem;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13.5px;
  line-height: 20px;
}
.weekLine {
  height: 1px;
  background: #a0a0a0;
  width: 53px;
  align-items: center;
}
.footerLinear {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 100;
  height: 60px;
  background: linear-gradient(
    357.58deg,
    #000000 18.28%,
    rgba(0, 0, 0, 0) 97.22%
  );
}
.dataContent {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.weekOneScreenText {
  padding: 0 20px;
}
.dateButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  padding: 0 15px;
}

.dateText {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 10px;
  /* margin: 10% 0 6%; */
}
.dateText .helpButtonIconTest {
  display: flex;
  width: 23px;
  height: 23px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.textRankSubDescription {
  background-color: var(--black);
  color: var(--white);
  font-size: var(--font-size-xs);
  line-height: var(--lineheight-xxs);
  font-family: var(--NotoSans);
  font-weight: var(--font-weight-regular);
  margin: auto;
  width: fit-content;
  padding: 3px 20px;
  border-radius: 100px;
}

.textBelowHeaderParticipation {
  background-color: var(--black);
  color: var(--white);
  font-size: var(--font-size-xs);
  line-height: var(--lineheight-xxs);
  font-family: var(--NotoSans);
  font-weight: var(--font-weight-regular);
  margin: auto;
  width: fit-content;
  padding: 3px 20px;
  border-radius: 100px;
}

.messagesContainer {
  margin-top: 36px;
}

.confirmationPopupMessagesContainer {
  margin-top: 30px;
}

.contentClassName {
  margin-bottom: 30px;
}

.confirmationPopupFooter {
  margin-top: 0;
}

.cancelReservationFooter {
  margin-top: 46px;
}

.fromtoTimeServerContainer {
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  z-index: 100;
  background-image: url(../../assets/common/cafe.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 0 1em;
  justify-content: center;
  align-items: center;
}
.textFromToTimeServer {
  color: #ffffff;
  line-height: normal;
  font-size: 19.6px;
  text-align: center;
  letter-spacing: 1px;
  /* padding-right: 19px;
  padding-left: 19px; */
}

.navbar {
  height: 60px;
}

.navIcon {
  width: 25px;
  height: 25px;
}
/**/
.weekButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.weekButtonContainer > div:not(:last-child) {
  margin-right: 7px;
}
.weekButton {
  width: 86%;
  height: 40px;
  background: linear-gradient(182.87deg, #646464 -12.73%, #242424 80.72%);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.weekButtonText {
  width: 100%;
  text-align: center;
  line-height: 26px;
  color: white;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 200;
  white-space: nowrap;
}
.countdownTime {
  margin-top: 5px;
}
.labelCheckBox {
  margin-right: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkBox {
  margin-right: 4px;
}

.buttonDate {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--Main-Color);
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  letter-spacing: 1px;
  font-weight: 400;
}

.buttonNegative {
  background-color: #a0a0a0;
}

.warningText {
  color: #ff0000;
  font-size: 11px;
}

.mrB {
  flex: 1;
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  position: relative;
}
.mrB .contentContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mrB .headerContent {
  min-height: 156px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  letter-spacing: 0.035em;
  color: #ffffff;
  margin: 0 1rem;
  margin-bottom: 15%;
}
.mrB .mrBachelor {
  width: 154.6px;
  height: 237.1px;
}
.buttonGroupHasTwoDate > div:not(:first-child) {
  margin-top: 15px;
}
.dateParticipatedUnselected::after {
  border: 2px dashed var(--Main-Color);
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23c0a76e' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e"); */
  content: '';
  position: absolute;
  width: 100%;
  height: 63px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
}
.dateTitle {
  font-size: 18px;
  color: white;
  align-self: flex-start;
  font-weight: 300;
  padding: 8px 15px;
  background: #1c1c1c;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: -25px;
  z-index: 0;
  padding-right: 6%;
}

.dateButtonClosed {
  font-size: 20px;
  color: #555555;
  justify-content: center;
}

.dateButtonClosedWhiteMode {
  font-size: 20px;
  color: #c4c4c4;
  background-color: white;
  justify-content: center;
}

.dateButtonContainerClosed {
  padding: 0 30px;
}
.buttonGroup {
  width: 100%;
  padding: 25px 15px;
  background: #1c1c1c;
  border-radius: 10px;
}
.recommendText {
  color: var(--Main-Color);
  margin-top: -4px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
}
.recommendTextWhite {
  color: black;
  margin-top: -4px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
}
.dateIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dateIcon.dateIconSelected path {
  fill: var(--white);
}

.dateIconSelected [data-icon='sun'] {
  fill: white;
}

.dateIconSelected [data-icon='line'] {
  stroke: white;
}

.dateIconSelectedForAfterEvening path {
  stroke: var(--white);
}

.dateIconSelectedForAfterEvening path:nth-child(2),
.dateIconSelectedForAfterEvening path:nth-child(3) {
  fill: var(--white);
}

.containerFullWhite {
  background-color: #f8f8f8;
}
.textBlack {
  color: black !important;
}
.textWhite {
  color: white;
}
.textLightDarkGray {
  color: #999999;
}
.dateTitleWhiteMode {
  background: #f8f8f8;
  border-left: 1px solid #c4c4c4;
  color: black;
  width: 35%;
}
.dateButtonContainerWhiteMode:before {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-left: 1px solid #c4c4c4;
  border-top: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  content: '';
  position: relative;
  display: inline-block;
  width: 35%;
  height: 20px;
  align-self: start;
  margin-bottom: -19px;
  z-index: 100;
}
.buttonGroupWhiteMode {
  background: #f8f8f8;
  border: 1px solid #c4c4c4;
}
.dateButtonWhite {
  background-color: white;
  box-shadow: 0 0 5px gray;
}
.dateButtonAwaitingCancel {
  background-color: #e0e0e0;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.inactiveButtonWhiteMode {
  background-color: #e0e0e0 !important;
  color: white;
}
.activeWhiteMode {
  box-shadow: 0 0 15px gray;
}

.inactiveWhiteMode {
  background: #c4c4c4;
}

.dateButtonSelected {
  background: var(--Main-Color);
}

.subDescription {
  font-size: 16px;
  font-weight: 400;
  color: #afafaf;
  text-align: center;
}
.centerMsg {
  align-items: center;
  justify-content: center;
}
.bigPopupTitle {
  font-size: 23px;
}
.heldFridayContentPopup {
  margin-bottom: 20px;
}
.imageContentMsg {
  margin-top: 10px;
}

.heriPopupContent {
  background-color: #012250;
  border-color: #012250;
}

.heriPositiveButton {
  border-radius: 30px;
  height: 50px;
}

.heriPopupBody {
  padding: 5px 21px;
}

.heriPopupFooter {
  height: auto;
  margin-top: 17px;
}

.heriImageContent {
  margin-top: 20px;
}

.dailyDatingPopupContent {
  background-color: #fcc900;
  border-color: #fcc900;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.buttonHighlight {
  box-shadow: 0 0 15px var(--Main-Color);
}

.dailyDatingPopupTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--NotoSerif);
}

.dailyDatingPopupTitleContainer .subTitle {
  display: flex;
  position: relative;
}

.dailyDatingPopupTitleContainer .subTitle .decorImage1 {
  max-width: 55px;
  max-height: 65px;
  margin-right: 2px;
}

.dailyDatingPopupTitleContainer .subTitle .decorImage2 {
  max-width: 45px;
  max-height: 58px;
  margin-left: 2px;
}

.dailyDatingPopupTitleContainer .subTitle .content {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-top: 31px;
}

.dailyDatingPopupTitleContainer .title {
  font-weight: 600;
  font-size: 45.5px;
  line-height: 66px;
}

.dailyDatingPositiveButton {
  border-radius: 30px;
  height: 50px;
  background: #c89c34;
  color: #ffffff;
  order: 2;
}

.dailyDatingPopupMessagesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
}

.dailyDatingPopupMessagesContainer .messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  font-family: var(--NotoSerif);
}

.dailyDatingPopupMessagesContainer .messageImg {
  width: calc(100% + 26px);
  margin-top: 24px;
}

.dailyDatingFooterNote {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  order: 1;
  margin-bottom: 5px;
  text-align: center;
}

.dailyDatingPopupBody {
  padding: 5px 12px;
}

.everyDayDatingMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  margin-top: 20px;
}

.everyDayDatingMessageContainer .image {
  width: 80%;
}

.everyDayDatingMessageContainer .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

.textBold {
  font-weight: 700;
}

.everyDayDatinPositiveButton {
  order: 2;
}

.everyDayDatingFooterContainer {
  order: 1;
  text-align: center;
  margin-bottom: 3px;
  font-weight: 300;
  font-size: 12px;
}

.blackUserWelcomePopupImg {
  width: calc(100% + 45px);
  margin-left: -22px;
  margin-top: -44px;
}

.blackUserWelcomeTitleText {
  font-weight: var(--font-weight-bold);
  line-height: 21px;
  font-size: var(--font-size-l);
  text-align: center;
  margin-top: 16.5px;
}

.bachelorMemberWelcomeTitleText {
  font-weight: var(--font-weight-bold);
  line-height: 21px;
  font-size: var(--font-size-l);
  text-align: center;
  margin-top: 19px;
  letter-spacing: 0.138px;
}

.blackUserWelcomeTitleContainer {
  display: flex;
  flex-direction: column;
}

.whatChangeDatingPopupMessageContainer {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  margin-top: 20px;
}

.whatChangeDatingPopupMessageContainer .subTitle {
  margin-bottom: 20px;
}

.bachelorMemberTitleContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bachelorMemberImg {
  width: calc(100% + 45px);
  margin-left: -22px;
  margin-top: -44px;
}

.bachelorMemberWelcomeImg {
  width: calc(100% + 44px);
  margin-left: -22px;
  margin-top: -44px;
}

.bachelorMemberImgTestVer {
  aspect-ratio: 321.874/172.543;
  width: 100%;
  height: auto;
}

.bachelorMemberTitleText {
  font-weight: var(--font-weight-bold);
  color: var(--black);
  line-height: 22.927px;
  font-size: 17.196px;
  text-align: center;
  margin-top: 19.1px;
  letter-spacing: 0.138px;
}

.miniTitleFirstTimeEnterTheAppAfterApproved {
  color: rgba(26, 26, 26, 0.94);
  text-align: center;
  font-family: var(--NotoSans);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: var(--font-weight-medium);
  line-height: var(--lineheight-s);
  letter-spacing: 1.4px;
}

.titleFirstTimeEnterTheAppAfterApproved {
  color: rgba(26, 26, 26, 0.94);
  text-align: center;
  font-family: var(--NotoSans);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  line-height: var(--font-size-xl);
  letter-spacing: 0.128px;
}

.textMessages {
  color: rgba(26, 26, 26, 0.94);
  font-family: var(--NotoSans);
  font-size: 15px;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: normal;
}

.footer {
  color: var(--gray-300);
  font-family: var(--NotoSans);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: var(--lineheight-xxs);
  letter-spacing: 0.096px;
  margin-top: var(--spacing-large);
}

.containerFirstTimeEnterTheAppAfterApproved {
  margin-bottom: var(--spacing-large);
}

.containerFirstTimeEnterTheAppAfterApprovedForFemale {
  padding: 0;
}

.popupContentFirstTimeEnterTheAppAfterApprovedForFemaleStyle {
  border: 0;
}

.messagesFirstTimeEnterTheAppAfterApprovedForFemaleStyle {
  color: var(--black);
  font-family: var(--NotoSans);
  font-size: 15.285px;
  font-style: normal;
  font-weight: var(--font-weight-regular);
  line-height: 22.927px;
  letter-spacing: 0.168px;
}

.messagesContainerAddClassFirstTimeEnterTheAppAfterApprovedForFemaleStyle {
  margin: 20.06px 22.93px 19.11px 23.93px;
}

.footerClassNameFirstTimeEnterTheAppAfterApprovedForFemaleStyle {
  padding: 30.57px;
  margin-top: 0;
}

.contentFirstTimeEnterTheAppAfterApprovedForFemaleStyle {
  margin-bottom: 0;
}

.buttonContainerFirstTimeEnterTheAppAfterApprovedForFemaleStyle {
  border-radius: 3.822px;
}

.summerCampaignPopupBanner {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.summerCampaignPopupContent {
  margin-bottom: 23px !important;
}

.summerCampaignPopupMessagesContainer {
  margin-top: 23px !important;
}

.summerCampaignPopupButton {
  display: flex;
  height: 60px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--Main-Color);
  color: #000;
  font-size: 16.6px;
  line-height: normal;
  letter-spacing: 1px;
  font-weight: 400;
}

.summerCampaignPopupPositiveButton {
  margin-bottom: 7.5px !important;
}

.summerCampaignPopupNegativeButton {
  background-color: #c2c2c2;
}

.summerCampaignPopupTitle {
  font-size: 17.2px !important;
  line-height: 23px !important;
  letter-spacing: 0.14px !important;
}

.summerCampaignPopupCheckbox {
  display: flex;
  justify-content: center;
}

.motivateMaleUserPhotosContainer {
  background-image: url(../../assets/Participation/motivate_background.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 13.5px 7.5px;
}

.motivateMaleUserPhotosTitle {
  display: flex;
  justify-content: center;
  color: var(--Main-Color);
  font-size: var(--font-size-m);
  font-family: var(--NotoSans);
  font-weight: var(--font-weight-bold);
  line-height: 13px;
}

.motivateMaleUserPhotosItemContainer {
  overflow-y: scroll;
  display: flex;
  padding-top: 17.96px;
}

.motivateMaleUserPhotosItemContainer::-webkit-scrollbar {
  display: none;
}

.motivateMaleUserPhotosItem {
  display: flex;
  flex-direction: column;
}

.motivateMaleUserImgWrap {
  position: relative;
  aspect-ratio: 1;
  width: 91px;
  height: auto;
  display: flex;
  justify-content: center;
}

.motivateMaleUserImg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.lastMotivateMaleUserPhotosImg {
  position: absolute;
  width: 85px;
  height: 32px;
  top: 34px;
  margin-left: auto;
  margin-right: auto;
  object-fit: scale-down;
}

.motivateMaleUserPhotosItemText {
  display: flex;
  justify-content: center;
  color: var(--black);
  font-family: var(--NotoSans);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
}

.bannerImageWrapper {
  width: 100%;
  height: auto;
  margin-right: var(--spacing-small);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 405px) {
  .dailyDatingPopupTitleContainer .title {
    font-size: 11vw;
  }
}

@media screen and (max-width: 392px) {
  .dailyDatingPopupTitleContainer .subTitle .content {
    font-size: 5.5vw;
  }
}

@media screen and (device-width: 320px) and (device-height: 568px) {
  .dateText {
    margin: 7% 0 4%;
  }

  .heriImageContent {
    margin-top: 0;
  }

  .heriPositiveButton {
    margin-bottom: 15px;
    height: 40px;
  }

  .heriButtonContainer {
    margin-bottom: 0;
  }
}

@media screen and (device-width: 375px) and (device-height: 667px) {
  .dateText {
    margin: 5% 0 3%;
  }
  .dailyDatingPopupTitleContainer .title {
    font-size: 41px;
  }
  .dailyDatingPopupMessagesContainer .messages {
    font-size: 14px;
  }
  .dailyDatingPopupTitleContainer .subTitle .content {
    font-size: 21px;
  }
}
